<template>
    <div>
        <img class="example-headImg" src="../assets/image/aboutus/05-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">新闻资讯</div>
        </div>
        <div
            class="container-1 no-news"
            v-if="box4List.length"
            style="padding-top: 3rem;
            margin-bottom:5rem"
        >
            <div v-for="item in newsList" :key="item.index">
                <div class="container4-carousel" style="border: 1px solid #EDEDED;">
                        <img :src="item.url" class="container4-carousel-img">
                        <div class="box4">
                            <div class="container4-carousel-title">{{item.title}}</div>
                            <div class="container4-carousel-date">{{item.date}}</div>
                            <div class="container4-carousel-content">{{item.content}}</div>
                            <div class="container4-carousel-more" @click="jumpRout(item.id,'5')">阅读更多<i class="el-icon-right" style="margin-top:0.8rem"></i></div>
                        </div>
                    </div>
            </div>
        </div>
        <!-- <div v-else class="container-1 no-news"
           style="padding-top: 10rem;margin-bottom:5rem"
           v-loading="loading"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading">
        </div> -->
        <div v-else class="container-1 no-news" style="padding-top: 10rem;margin-bottom:5rem">
            <div class="loading-auto">
               <i v-for="(item,index) in 72"
               :style="{'--rz':(index *5 )+'deg','--delay':(4 / 72 * index - 4)+'s','--tx':(Math.random(1000)*150)+'px','--ty':(Math.random(1000)*50)+'px','--hue':index*5}">
                </i>
            </div>
            <span class="loading-font">拼命加载中...</span>
        </div>
        <el-pagination
            class="pagination-style"
            v-if="box4List.length"
            background
            layout="total, prev, pager, next"
            :page-size="5"
            @current-change="handleCurrentChange"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
import store from '@/plugins/store';
import { getAction } from '@/utils/request.js';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'新闻资讯' },
        ]
    },
    data() {
        return {
            box4List:[],
            newsList:[],
            total:0,
            currentPage:1,
            loading: false
        };
    },

    mounted() {
        this.init()
        this.activeIndex = '5'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // this.box4List.reverse()
        console.log(window.location.href.indexOf('news') != -1)
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        handleCurrentChange(val) {
            this.currentPage = val
            this.newsList = []
            for(let i = (this.currentPage - 1) * 5; i < (this.currentPage - 1) * 5 + 5; i++){
                if(this.box4List[i]){
                    this.newsList.push(this.box4List[i])
                }
            }
        },
        async init(){
            this.loading = true
            const res = await getAction(`/wp/wp-json/wp/v2/posts?categories=5&per_page=100`);
            if(res){
                res.map((element,index) => {
                    let oneNew = {}
                    oneNew.index = index;
                    oneNew.title = element.title.rendered
                    let date = element.date.split('T')
                    let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                    oneNew.date = newDate
                    oneNew.content = element.content.rendered.split('<p>')[1].split('</p>')[0]
                    oneNew.route = `/news-detail`
                    oneNew.id = element.id
                    this.box4List.push(oneNew)
                    getAction(`/wp/wp-json/wp/v2/media/${element.featured_media}`).then((res2) => {
                        oneNew.url = res2.guid.rendered
                        oneNew.url = oneNew.url.replace("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
                    });

                    // oneNew.url = res2.guid.rendered
                    // this.box4List.push(oneNew)
                    // this.box4List[index] = JSON.parse(JSON.stringify(oneNew))

                });
                setTimeout(()=>{
                   this.box4List.sort((function(a,b) {
                        return a.index-b.index
                    }))
                    this.total = this.box4List.length
                    for(let i = 0; i < 5; i++){
                        if(this.box4List[i]){
                         this.newsList.push(this.box4List[i])
                        }
                    }
                },500)
            }
            this.loading = false
        },
        jumpRout(route,index){
            this.activeIndex = index
            this.newsIndex = false
            // if(route,indexOf('news') != -1){
            //     this.newsIndex = true
            // }
            localStorage.setItem('headId',index);
            // localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push({
                path:'/news-detail',
                query:{
                    id:route
                }
            })

            this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:this.newsIndex
            });
            console.log("test store",this.$store.state);
        }
    },
};
</script>

<style lang="less" scoped>
.pagination-style{
    margin-bottom: 3rem;
}
.no-news{
    min-height: 50rem;
}
.loading-auto{
    width: 30rem;
    height: 30rem;
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    // 执行选装动画：动画名，时长，线性的 无限循环次
    animation:spin 8s linear infinite;
    // 模糊滤镜+对比度滤镜实现溶球效果
    // filter: blur(2px) contrast(2)
}
.loading-auto i{
    width:1.6rem;
    height:1.6rem;
    border-radius:50%;
    background-color:hsl(var(--hue),75%,75%);
    position:absolute;
    left:15rem;
    top:15rem;
    transform:rotateZ(var(--rz)) translateY(8rem);
    animation:move 4s ease-in-out infinite;
    animation-delay:var(--delay);
}
.loading-font{
    color: #11A84F;
    // font-size: 2rem;
}
// 定义动画
// 圆环旋转动画
@keyframes spin {
    to{
        transform:rotate(360deg)
    }
}
// 溶球移动消失动画
@keyframes move {
    0%{
        transform:rotateZ(var(--rz)) translateY(6.4rem) translate(0,0)
        scale(0);
    }
    2%{
        transform:rotateZ(var(--rz)) translateY(6.4rem) translate(0,0)
        scale(1.25);
    }
    20%{
        transform:rotateZ(var(--rz)) translateY(6.4rem) translate(0,0)
        scale(1.25);
    }
    90%,100%{
        transform:rotateZ(var(--rz)) translateY(6.4rem) translate(var(--tx),var(--ty))
        scale(0);
    }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #11A84F;
}
</style>
